@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
@import "_variables";

* {
  font-family: "Karla", sans-serif;
  box-sizing: border-box;
  list-style: none;
  color: unset;
}
body {
  background-color: $navy;
  color: $light-gray;
}
.App {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 32px;
}

.nav-container {
  height: 100px;
}

.section-header {
  color: $gold;
  text-decoration: underline;
  text-underline-offset: 12px;
  margin-bottom: 42px;
  letter-spacing: -0.5px;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  @media (max-width: 990px) {
    display: block;
    padding: 72px;
    height: auto;
  }
  @media (max-width: 600px) {
    display: block;
    padding: 24px;
    height: auto;
  }
}

.header-text {
  .header-name {
    font-size: 42px;
    margin: 12px 0;
    color: $gold;
    letter-spacing: -2px;
    text-decoration: underline;
    text-underline-offset: 8px;
  }
  .header-subtext {
    font-size: 16px;
  }
  p {
    margin-top: unset;
    font-size: 24px;
    @media (max-width: 600px) {
      font-size: 20px;
    }
  }
  button {
    padding: 15px 35px;
    border-radius: 40px;
    background: #00d084;
    border: unset;
    font-size: 16px;
    color: #16213e;
    font-weight: 700;
    &:hover {
      background: #00a66a;
    }
    a {
      text-decoration: none;
    }
  }
  .header-actions {
    display: flex;
  }

  ul {
    display: flex;
    gap: 30px;
  }

  .fab {
    font-size: 24px;
    &:hover {
      color: $gold;
    }
  }
}

.header-picture-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 32px;
}
.header-picture {
  width: 350px;
  border-radius: 50%;
  box-shadow: 12px 0 #c9c9c9, 24px 0 #dcdcdc, 36px 0 #e5e5e5;
  @media (max-width: 767px) {
    width: 250px;
  }
}

.skills-container {
  margin-bottom: 50px;
  h2 {
    color: $gold;
  }
  .skills-box {
    background-color: rgb(245, 244, 252);
    border-radius: 8px;
    border: 3px #f4d93b solid;
    box-shadow: rgb(0 0 0 / 5%) 0px 0px 13px;
  }
  ul {
    display: grid;
    grid-template-columns: repeat(5, auto);
    padding: 20px 0;
    @media (max-width: 600px) {
      grid-template-columns: repeat(4, auto);
    }
    @media (max-width: 500px) {
      grid-template-columns: repeat(3, auto);
    }
  }
  li {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    margin: 0 20px;
    padding: 20px 0;
    font-weight: 700;
    color: #16213e;
    .skill-icon {
      width: 50px;
      height: 50px;
      padding-bottom: 10px;
    }
  }
}

.projects-container {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  row-gap: 20px;
  justify-content: center;
  gap: 72px;
  @media (max-width: 950px) {
    grid-template-columns: 1fr;
  }
}

.project-card {
  display: grid;
  grid-template-rows: 6fr 4fr;
  width: 375px;
  height: 300px;
  border-radius: 12px;
  background: white;
  margin: 0 auto;
  text-decoration: none;
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 13px;
  @media (max-width: 950px) {
    width: 85%;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
  &:hover {
    transform: translatey(-4px);
  }
  .project-image {
    background: rgba(245, 244, 252);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    overflow: hidden;
    img {
      margin: auto;
    }
    &.trade-background {
      background: #f53c32;
      display: table;
      h2 {
        margin: 0 auto;
        text-align: center;
        vertical-align: middle;
        display: table-cell;
        font-size: 42px;
      }
    }
  }
  .project-text {
    text-align: center;
    align-self: center;
    color: black;
    padding: 0 12px;
    h3 {
      margin: 12px 0;
    }
    p {
      margin: 0 0 12px 0;
    }
  }
  .project-skills {
    gap: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-inline-start: unset;
    flex-wrap: wrap;
  }
}

.test-container {
  height: 85%;
  width: 85%;
  display: flex;
  margin: auto;
  position: relative;
}

.case-study-container {
  width: 90%;
  background-color: rgb(245, 244, 252);
  margin: 0 auto;
  border-radius: 12px;
  padding: 42px;
  margin-bottom: 42px;
  @media (max-width: 600px) {
    width: unset;
    padding: 24px;
  }
  .case-study-card {
    border: 1px solid #eee;
    box-shadow: 0px 14px 28px rgb(134 151 168 / 8%);
    border-radius: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 990px) {
      grid-template-columns: 1fr;
    }
    margin-bottom: 20px;
    .case-study-card__image {
      overflow: hidden;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      position: relative;
    }
    img {
      overflow: hidden;
      max-height: 85%;
      max-width: 85%;
    }
    .case-study-card__text {
      color: black;
      padding: 20px;
      li {
        list-style: circle;
        margin-bottom: 12px;
      }
    }
  }
}

.monnlight {
  filter: drop-shadow(0 0 0.75rem silver);
}

.center-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
