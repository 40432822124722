@import "_variables";

.subpage-container {
  padding: 50px;
  li {
    list-style: circle;
    margin-bottom: 12px;
  }
}

.subpage-header {
  color: $gold;
  text-align: center;
}

.first-picture {
  max-width: 100%;
}

.second-picture {
  max-width: 75%;
  // float: right;
  display: block;
}

p {
  font-size: 18px;
}

.text-container {
  span {
    color: $gold;
  }
  h2 {
    color: $gold;
  }
}

.home-button {
  font-size: 24px;
  text-decoration: none;
  &:hover {
    color: $gold;
  }
}
